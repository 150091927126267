import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DateInputComponent } from './date-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
@NgModule({
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatDatepickerModule,
    MatProgressBarModule,
    FuseSharedModule
  ],
  declarations: [
    DateInputComponent,
  ],
  exports: [
    DateInputComponent,
  ]
})
export class DateInputModule { }
