import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Main Menu',
        type     : 'group',
        children : [
            {
                id       : 'customer',
                title    : 'Dashboard',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/admin/dashboard'
            },
            {
                id       : 'customer',
                title    : 'Export',
                type     : 'item',
                icon     : 'insert_drive_file',
                url      : '/admin/export'
            },
            // {
            //     id       : 'lead',
            //     title    : 'Lead',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/lead'
            // },
            // {
            //     id       : 'work',
            //     title    : 'My Work',
            //     type     : 'collapsable',
            //     icon     : 'work',
            //     children : [
            //         {
            //             id       : 'createWork',
            //             title    : 'Create New Request',
            //             type     : 'item',
            //             url      : '/work/work-request',
            //         },
            //         {
            //             id       : 'createWork3',
            //             badge: {
            //                title:"10",
            //                bg:"#ff0000",
            //                fg:"#ffffff"
            //             },
            //             title    : 'My Request',
            //             type     : 'item',
            //             url      : '/work/my-request',
            //         },
            //         {
            //             id       : 'createWork2',
            //             badge: {
            //                title:"35",
            //                bg:"#ff0000",
            //                fg:"#ffffff"
            //             },
            //             title    : 'Review Request',
            //             type     : 'item',
            //             url      : '/work/review-request',
            //         },
            //         {
            //             id       : 'createWork3',
            //             title    : 'All Request',
            //             type     : 'item',
            //             url      : '/work/request-list',
            //         }
            //     ]
            // },
            {
                id       : 'authentication',
                title    : 'Authentication',
                type     : 'collapsable',
                icon     : 'lock',
                children : [
                    {
                        id       : 'user',
                        title    : 'User',
                        type     : 'item',
                        url      : '/admin/user',
                    },
                    // {
                    //     id       : 'role',
                    //     title    : 'Role',
                    //     type     : 'item',
                    //     url      : '/admin/role',
                    // },
                    
                ]
            },
            {
                id       : 'setiing',
                title    : 'Setiing',
                type     : 'collapsable',
                icon     : 'settings',
                children : [
                    {
                        id       : 'login',
                        title    : 'Log Login',
                        type     : 'item',
                        url      : '/admin/log/login',
                    },
                    {
                        id       : 'facebook',
                        title    : 'Manage Facebook',
                        type     : 'item',
                        url      : '/admin/facebook',
                    },
                    
                ]
            },
            // {
            //     id       : 'master',
            //     title    : 'Master Data',
            //     type     : 'collapsable',
            //     icon     : 'database',
            //     children : [
            //         {
            //             id       : 'master1',
            //             title    : 'ประเภทแผนงาน',
            //             type     : 'item',
            //             url      : '/master/plan-type',
            //         },
            //         {
            //             id       : 'master2',
            //             title    : 'เงือนไข',
            //             type     : 'item',
            //             url      : '/master/condition',
            //         },
            //         {
            //             id       : 'master3',
            //             title    : 'Login Url link',
            //             type     : 'item',
            //             url      : '/master/login-url-link',
            //         },
            //         {
            //             id       : 'master4',
            //             title    : 'Dashboard Url link',
            //             type     : 'item',
            //             url      : '/master/dashboard-url-link',
            //         },
            //         {
            //             id       : 'master5',
            //             title    : 'หน่วยงาน',
            //             type     : 'item',
            //             url      : '/master/effect-unit',
            //         },
            //         {
            //             id       : 'master6',
            //             title    : 'บริษัท',
            //             type     : 'item',
            //             url      : '/master/company',
            //         },
            //     ]
            // },
            // {
            //     id       : 'configure',
            //     title    : 'Configure',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/configure'
            // },
            // {
            //     id       : 'adminUser',
            //     title    : 'Users',
            //     type     : 'collapsable',
            //     icon     : 'lock',
            //     children : [
            //         {
            //             id       : 'user1',
            //             title    : 'PTT Non TSO User',
            //             type     : 'item',
            //             url      : '/user/nontso',
            //         },
            //         {
            //             id       : 'user2',
            //             title    : 'Extenal User',
            //             type     : 'item',
            //             url      : '/user/extenal',
            //         },
            //         {
            //             id       : 'group2',
            //             title    : 'User Group',
            //             type     : 'item',
            //             url      : '/user/group',
            //         },
            //         {
            //             id       : 'group3',
            //             title    : 'TSO Unit',
            //             type     : 'item',
            //             url      : '/user/unit',
            //         },
            //         {
            //             id       : 'user3',
            //             title    : 'Special Permission User',
            //             type     : 'item',
            //             url      : '/user/permission',
            //         }
            //     ]
            // },
        ]
    }
];
