import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { AuthenticationService } from '../_services';



@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
           
            if (currentUser.isRePassword === 1) {
                this.router.navigate(['/auth/reset-password']);
                return false;
            }
            if(currentUser.role === 'Dealer'){
                this.router.navigate(['/lead/list']);
                return false;
            }

            if(currentUser.role === 'CallCenter'){
                this.router.navigate(['/lead/list-callcenter']);
                return false;
            }
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
                this.router.navigate(['/admin/dashboard']);
                return false;
            }
  
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    canLoad(route: Route): boolean {
        return true;
        const currentUser = this.authenticationService.currentUserValue;
       
        if (currentUser) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
                // role not authorised so redirect to home page
               // this.router.navigate(['/admin/dashboard']);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
      //  this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    
}