import { BehaviorSubject, Observable } from 'rxjs';

export class DataListModel<T>{
    datas: T[] ;
    data: T;
    onDataChanged: BehaviorSubject<T[]>;
    onAllDataChanged: BehaviorSubject<T[]>;
    onItemChanged: BehaviorSubject<T>;
    totalRecord: number;
    totalResponsed: number;
    totalBooked: number;
    totalNoResponse: number;
    totalWrongNumber: number;
    totalPage: number;
    pageSize = 25;
    page = 0;
    sort = 'Id';
    dir = 'desc';
    kw = '';
    catId: number;
    DateType: number;
    DateFrom: string;
    DateTo: string;
    validType: number;
    validFrom: string;
    validTo: string;
    HotelId: number;
    kwType = '';
    status: number = null;

}
