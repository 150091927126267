import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserModel } from 'app/models/user.model';
import { ConnectionService } from './connection.service';
import { EndPointModel } from 'app/models/enpoint.model';
//import { PlanTypeModel } from 'app/models/plan-type.model';
// import { UnitModel } from 'app/models/unit.model';
// import { LocationItemModel } from 'app/models/location-item.model';
// import { AreaModel } from 'app/models/area.model';
// import { ConditionModel } from 'app/models/condition.model';
// import { EffectUnitModel } from 'app/models/effect-unit.model';
// import { CompanyModel } from 'app/models/company.model';
@Injectable()
export class GlobalService {
    sessionLoginLead: boolean;
    contentID: String;
    user: UserModel;
    token: string ;
    onTokenChange: BehaviorSubject<string> ;
    onUserChange: BehaviorSubject<UserModel>;
    //ddlPlanTypies: PlanTypeModel[];
    // ddlUnits: UnitModel[];
    // ddlLocationItems: LocationItemModel[];
    // ddlAreas: AreaModel[];
    // ddlConditions: ConditionModel[];
    ddlShippers: UserModel[];
    // ddlEffectUnits: EffectUnitModel[];
    // ddlCompanies: CompanyModel[];
    constructor(private _service: ConnectionService, private _fuseNavigationService: FuseNavigationService, ) {
        this.token = '';
       
        this.user = new UserModel({});
        this.contentID = 'show';
        this.onTokenChange = new BehaviorSubject<string>('');
        this.onUserChange = new BehaviorSubject<UserModel>(new UserModel({}));
        // this.ddlPlanTypies = [];
        // this.ddlLocationItems = [];
        // this.ddlAreas = [];
        if (sessionStorage.getItem('token'))
        {
            this.token = sessionStorage.getItem('token');
            this.onTokenChange.next(this.token);
        }
        this.onTokenChange.subscribe(result => {
            this.token = result;
            this.getUserProfile();
        });

    }


    getConfig(): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }



    getUserProfile()
    {
        // this.sessionLoginLead = false;
        // if (this.token != '') {
        //     sessionStorage.setItem('token', this.token);
        //     this._service.post(EndPointModel.User.GetProfile, null, UserModel).then((response: UserModel) => {
        //         this.user = response;
        //         this.onUserChange.next(this.user);
        //     });

            
        // }
    }


  
}
