export class UserModel {
    id: number;
    user: string;
    role: string;
    userName: string;
    confirmPassword: string;
    password: string;
    branchCode: string;
    constructor(data)
    {
        this.id = (typeof (data.id) == 'number' ? data.id : null);
        this.user = data.user || null;
        this.role = data.role || null;
        this.userName = data.userName || null;
        this.confirmPassword = data.confirmPassword || null;
        this.password = data.password || null;
        this.branchCode = data.branchCode || null;
    }
	 
}
