<mat-toolbar style="height: 40px;">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <div fxFlex="0 0 auto" class="mat-body-1">&copy; 2020 A.P. Honda LIMITED.  All Rights Reserved.</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button href='#'>Manual</a>
        </div>

    </div>

</mat-toolbar>
