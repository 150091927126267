import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { HttpClient , HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import { MatDialog  } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DataListModel } from 'app/models/data-list.model';

@Injectable()
export class ConnectionService 
{

    constructor(  private http: HttpClient , private _matDialog: MatDialog, private _router: Router)
    {

    }

    noTokenPost(url: string, data: any, responseClass: any, splash: boolean = true): Promise<any>{
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            })
        };
        return new Promise((resolve, reject) => {
            if (splash)
            {
             //   this.fuseSplashScreen.show();
            }
            this.http.post(`${environment.apiUrl}${url}`, data, httpOptions).subscribe((response: any) => {
      //          this.fuseSplashScreen.hide();
                if (response == null){
                    resolve(null);
                }else{
                    resolve( new responseClass(response));
                }
                resolve(response);
            }, error => {
                if (splash)
                {
               //     this.fuseSplashScreen.hide();
                }
                this.handleError(error);
               
                reject(error);
            });

        });
    }

    post(url: string, data: any, responseClass: any, splash: boolean = true): Promise<any>{
        let token = (sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer '+sessionStorage.getItem('token')
            })
        };
        return new Promise((resolve, reject) => {
    
            if (splash)
            {
               // this.fuseSplashScreen.show();
            }
            this.http.post(`${environment.apiUrl}${url}`, data, httpOptions).subscribe((response: any) => {
               // this.fuseSplashScreen.hide();
                if (response == null){
                    resolve(null);
                }else{
                    if (Array.isArray(response))
                    {
                        resolve(response.map(c => new responseClass(c)));
                    }
                    else{
                        resolve( new responseClass(response));
                    }
                }
                resolve(response);
            }, error => {
                if (splash)
                {
              //      this.fuseSplashScreen.hide();
                }
                this.handleError(error);
               
                reject(error);
            });

        });
    }

    
    patch(url: string, data: any, responseClass: any, splash: boolean = true): Promise<any>{
        let token = (sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer '+sessionStorage.getItem('token')
            })
        };
        return new Promise((resolve, reject) => {
    
            if (splash)
            {
             //   this.fuseSplashScreen.show();
            }
            this.http.patch(`${environment.apiUrl}${url}`, data, httpOptions).subscribe((response: any) => {
                //this.fuseSplashScreen.hide();
                if (response == null){
                    resolve(null);
                }else{
                    if (Array.isArray(response))
                    {
                        resolve(response.map(c => new responseClass(c)));
                    }
                    else{
                        resolve( new responseClass(response));
                    }
                }
                resolve(response);
            }, error => {
                if (splash)
                {
                  //  this.fuseSplashScreen.hide();
                }
                this.handleError(error);
               
                reject(error);
            });

        });
    }

    
    listData(url: string, data: any, responseClass: any, splash: boolean = true): Promise<any>{
       // let token = (sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer '+sessionStorage.getItem('token')
            }),
            params: {
                ...data
            }
        };
        return new Promise((resolve, reject) => {
            // if(!token)
            // {
            //     this._router.navigate(['login']);
            //     resolve(null);
            //     return;
            // }
            if (splash)
            {
               // this.fuseSplashScreen.show();
            }
            this.http.get(`${environment.apiUrl}${url}`, httpOptions).subscribe((response: any) => {
                //this.fuseSplashScreen.hide();          
                let returnData = new DataListModel<typeof responseClass | null>();
                if (response == null || !Array.isArray(response.datas))
                {
                    returnData.totalRecord = 0;
                    returnData.datas = [];
                }
                else if (Array.isArray(response.datas)) {
                    returnData.totalRecord = response.totalRecord;
                    returnData.totalResponsed = response.totalResponsed ?? 0;
                    returnData.totalBooked = response.totalBooked ?? 0;
                    returnData.totalNoResponse = response.totalNoResponse ?? 0;
                    returnData.totalWrongNumber = response.totalWrongNumber ?? 0;
                    returnData.datas = response.datas.map(c => {
                        return new responseClass(c)
                    });
                }
                else {
                    returnData.totalRecord = 0;
                    returnData.datas = [];
                }
                resolve(returnData);
            }, error => {
                if (splash)
                {
                    //this.fuseSplashScreen.hide();
                }
                this.handleError(error);
               
                reject(error);
            });

        });
    }
    handleError(error: any)
    {
        if(error == 'Unauthorized'){
            Swal.fire({
                title: 'Error',
                text: 'Not Logged In , Please login to continue.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: 'No'
            });
        }else if (error.status == 400) {
            let errorMessage = '';

            if (error.error.message)
            {
                errorMessage  = error.error.message;
            }
            else
            {
                errorMessage  = 'Api Error 400';
            }

            Swal.fire({
                title: 'Error',
                text: errorMessage,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: 'No'
              });
        }
        else if (error.status == 500) {
            Swal.fire({
                title: 'Error',
                text: 'API Error',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: 'No'
              });
        } 
        else if (error.status == 404) {
            Swal.fire({
                title: 'Error',
                text: 'API Not Found',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: 'No'
              });
        } 
        else if (error.status == 401) {
            Swal.fire({
                title: 'Error',
                text: 'Autentication Fail',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: 'No'
              });
        }
        else {
            Swal.fire({
                title: 'Error',
                text: 'API Error',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                cancelButtonText: 'No'
              });
        }
    }
    get(url: string, data: any){
        let token = sessionStorage.getItem('token');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            }),
            params: {
                ...data
            }
        };
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.apiUrl}${url}`, httpOptions).subscribe(
                (response: any) => {
                    //this.fuseSplashScreen.hide();
                    resolve(response);
                }, error => {
                    //this.fuseSplashScreen.hide();
                    reject(error);
                }
            );
        });
    }

    async getNew(url: string, data: any, model){
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer '+sessionStorage.getItem('token')
            }),
            params: {
                ...data
            }
        };
        try {
            var response = await this.http.get(`${environment.apiUrl}${url}`, httpOptions).toPromise();
            return new model(response);
        } catch (error) {
            return error;
        }
    }

    async patchNew(url: string, data: any, model){
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer '+sessionStorage.getItem('token')
            })
        };
        try {
            var response = await this.http.patch(`${environment.apiUrl}${url}`, data, httpOptions).toPromise();
            return new model(response);
        } catch (error) {
            return error;
        }
    }

    async posthNew(url: string, data: any, model){
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Authorization': 'Bearer '+sessionStorage.getItem('token')
            })
        };
        try {
            var response = await this.http.post(`${environment.apiUrl}${url}`, data, httpOptions).toPromise();
            return new model(response);
        } catch (error) {
            return error;
        }
    }

    
}
