export const environment = {
    production: true,
    hmr       : false,
    apiUrl: 'https://leaddealer.cjworxspace.com/api/v1',
    uploadUrl: '/service/UploadImage',
    dateFormat: {
        parse: {
            dateInput: 'YYYY-MM-DD',
        },
        display: {
            dateInput: 'YYYY-MM-DD',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
        },
    }
};

// export const environment = {
//     production: false,
//     hmr       : false,
//     apiUrl: '/djoin-admin/api',
//     uploadUrl: '/service/UploadImage',
//     dateFormat: {
//         parse: {
//             dateInput: 'YYYY-MM-DD',
//         },
//         display: {
//             dateInput: 'YYYY-MM-DD',
//             monthYearLabel: 'MMM YYYY',
//             dateA11yLabel: 'LL',
//             monthYearA11yLabel: 'MMMM YYYY',
//         },
//     }
// };
