import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Role } from './models/role';
import { LoginComponent } from './main/login/login.component';
import { AuthGuard } from './main/_helpers';


const routes: Routes = [
   
    {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {
            roles: [
                Role.Admin,
                Role.User
            ]
        },
        children: [
            {
                path: 'dashboard',
                canLoad: [AuthGuard],
                loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'export',
                canLoad: [AuthGuard],
                loadChildren: () => import('./main/export/export.module').then(m => m.ExportModule)
            },
            {
                path: 'user',
                canLoad: [AuthGuard],
                loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'role',
                canLoad: [AuthGuard],
                loadChildren: () => import('./main/role/role.module').then(m => m.RoleModule)
            },
            {
                path: 'log',
                canLoad: [AuthGuard],
                loadChildren: () => import('./main/log/login/login.module').then(m => m.LogLoginModule)

            },
            {
                path: 'facebook',
                canLoad: [AuthGuard],
                loadChildren: () => import('./main/setting/facebook.module').then(m => m.FacebookModule)

            },
        ]
    },
    {
        path: 'lead',
        children: [
            {
                path: '',
                loadChildren: () => import('./main/lead/lead.module').then(m => m.LeadModule)
            }
        ]
    },
   
    {
        path     : 'auth/reset-password',
        loadChildren: () => import('./main/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full'
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule { }
