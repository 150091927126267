import { Component, Input,  ViewChild, ElementRef, forwardRef, } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from 'environments/environment';
import * as _moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
const moment = _moment;
@Component({
  selector: 'date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateInputComponent,
      multi: true
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: environment.dateFormat },
  ]
})
export class DateInputComponent implements ControlValueAccessor {

  private rangeDate: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  output = '';
  @ViewChild('dateInput', { static: true }) dateInput: ElementRef;
  @Input('displayText') displayText: string;
  @Input('rangeType') rangeType: string;
  @Input('rangeDate') 
  set data(value)
    {
        this.rangeDate.next(value);
    }
  get data(){
        return this.rangeDate.getValue();
    }

  public _required: any = false;
  @Input()
  get required(): Boolean { return this._required; }
  set required(value: Boolean) {  this._required = this.coerceBooleanProperty(value); }

  minDate: Date = null;
  maxDate: Date = null;

  constructor() { 
    this.rangeDate.subscribe((dateData: string) => {
        if (moment(dateData).format('YYYY-MM-DD') != 'Invalid date' && (this.rangeType == 'start' || this.rangeType == 'end') )
        {
            if (this.rangeType == 'start')
            {
                this.maxDate = moment(dateData, 'YYYY-MM-DD').toDate();
            }
            if (this.rangeType == 'end')
            {
                this.minDate = moment(dateData, 'YYYY-MM-DD').toDate();
            }
        }

    });

  }
  onChanged: any = () => { };
  onTouched: any = () => { };
  writeValue(value: any) {
    if (value !== undefined && value !== null && value !== '') {
      this.output = moment(value).format('YYYY-MM-DD');
     // this.dateInput.nativeElement.value(this.output);
    }
  }
  propagateChange = (_: any) => { };
  registerOnChange(fn: any) {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== 'false';
  }
  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (this.output && this.output != ''){
    this.output = moment(event.value).format('YYYY-MM-DD');
    this.onChanged(moment(event.value).format('YYYY-MM-DD'));
    this.onTouched();
    }
  }
  validate({ value }: FormControl) {
    if (this._required && this.output == '') {
      return false;
    }
    else {
      return true;
    }
  }

}
