import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/material.module';
import { LoginComponent } from './login.component';
import { ConnectionService } from 'app/services/connection.service';
const routes = [
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MaterialModule
  ],
  providers: [
    ConnectionService
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }

