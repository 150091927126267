import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'app/models/user';
import { environment } from 'environments/environment';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(user: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/login`, { user, password })
            .pipe(map(user => {
                sessionStorage.setItem('currentUser', JSON.stringify(user));
                sessionStorage.setItem('userName',user?.userName);
                sessionStorage.setItem('token',user?.token);
                sessionStorage.setItem('BranchCode',user?.branchCode);
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('BranchCode');
        sessionStorage.removeItem('sessionLoginLead');
        this.currentUserSubject.next(null);
    }
}