<div id="login" fxLayout="row" fxLayoutAlign="start">
    <div id="login-intro" fxFlex fxHide fxShow.gt-xs fxLayoutAlign="center center">
      <span class="title">LEAD MANAGEMENT | BACKOFFICE</span>
    </div>
    <div id="login-form-wrapper" fusePerfectScrollbar *fuseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
      <div id="login-form" class="mt-12">
        <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
          <div class="logo">
            <img src="assets/images/logos/logo-honda.svg">
          </div>
          <form name="loginForm" [formGroup]="loginForm" novalidate>
  
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput formControlName="username">
                <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                <mat-error *ngIf="loginForm.get('username').hasError('required')">
                  Username is required
                </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password">
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error>
                    Password is required
                </mat-error>
            </mat-form-field>
  
            <button (click)="onSubmit()" mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                    [disabled]="loginForm.invalid">
                LOGIN
            </button>
  
        </form>
  
      </div>
    </div>
  </div>
  
  
    
  